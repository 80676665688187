exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contributors-tsx": () => import("./../../../src/pages/contributors.tsx" /* webpackChunkName: "component---src-pages-contributors-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-journal-tsx": () => import("./../../../src/pages/journal.tsx" /* webpackChunkName: "component---src-pages-journal-tsx" */),
  "component---src-pages-travel-tsx": () => import("./../../../src/pages/travel.tsx" /* webpackChunkName: "component---src-pages-travel-tsx" */),
  "component---src-templates-blog-archive-page-tsx": () => import("./../../../src/templates/blog-archive-page.tsx" /* webpackChunkName: "component---src-templates-blog-archive-page-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-continent-page-tsx": () => import("./../../../src/templates/continent-page.tsx" /* webpackChunkName: "component---src-templates-continent-page-tsx" */),
  "component---src-templates-contributors-page-tsx": () => import("./../../../src/templates/contributors-page.tsx" /* webpackChunkName: "component---src-templates-contributors-page-tsx" */),
  "component---src-templates-country-page-tsx": () => import("./../../../src/templates/country-page.tsx" /* webpackChunkName: "component---src-templates-country-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-tagged-page-tsx": () => import("./../../../src/templates/tagged-page.tsx" /* webpackChunkName: "component---src-templates-tagged-page-tsx" */),
  "component---src-templates-travel-story-tsx": () => import("./../../../src/templates/travel-story.tsx" /* webpackChunkName: "component---src-templates-travel-story-tsx" */)
}

